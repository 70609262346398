<template>
  <t-body class="account-page">
    <t-sidebar style="min-width: 27rem">
      <div class="intro">
        <div>
          <h4 class="intro__welcome">
            {{ $t('fields.welcome'), }}
          </h4>
          <h3 class="intro__name">
            {{ user.fullname }}
          </h3>
        </div>
      </div>

      <div v-if="!$fetchState.pending">
        <NuxtLink :to="{ name: $RouteEnum.ALERTS.CREATE }">
          <div class="onboarding-task" :class="{ 'onboarding-task--completed': hasAlerts }">
            <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
            <span class="onboarding-task__order">1</span>
            <div class="onboarding-task__body">
              <h3 class="onboarding-task__title">
                {{ $t('pages.alert.actions.create') }}
              </h3>
            </div>
          </div>
        </NuxtLink>
        <NuxtLink :to="{ name: $RouteEnum.PROFILES.CREATE }">
          <div class="onboarding-task" :class="{ 'onboarding-task--completed': hasProfiles }">
            <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
            <span class="onboarding-task__order">2</span>
            <div class="onboarding-task__body">
              <h3 class="onboarding-task__title">
                {{ $t('pages.profile.actions.create') }}
              </h3>
            </div>
          </div>
        </NuxtLink>
        <NuxtLink :to="{ name: $RouteEnum.EMAIL_TEMPLATES.CREATE }">
          <div class="onboarding-task" :class="{ 'onboarding-task--completed': hasEmailTemplates }">
            <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
            <span class="onboarding-task__order">3</span>
            <div class="onboarding-task__body">
              <h3 class="onboarding-task__title">
                {{ $t('pages.email_template.actions.create') }}
              </h3>
            </div>
          </div>
        </NuxtLink>
        <NuxtLink :to="{ name: $RouteEnum.REGISTRATION_CODES.CREATE }">
          <div
            class="onboarding-task"
            :class="{ 'onboarding-task--completed': hasRegistrationCodes }"
          >
            <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
            <span class="onboarding-task__order">4</span>
            <div class="onboarding-task__body">
              <h3 class="onboarding-task__title">
                {{ $t('pages.registration_code.actions.create') }}
              </h3>
            </div>
          </div>
        </NuxtLink>
      </div>
    </t-sidebar>

    <t-page>
      <h3 slot="header">
        {{ $t('global.headings.dashboard') }}
      </h3>

      <t-grid v-if="!$fetchState.pending">
        <t-grid-row>
          <t-grid-cell>
            <t-count-widget :label="$t('fields.alerts')" icon="bell" card>
              {{ organization.alerts_count }}
            </t-count-widget>
          </t-grid-cell>
          <t-grid-cell>
            <t-count-widget :label="$t('fields.profiles')" icon="folder" card>
              {{ organization.profiles_count }}
            </t-count-widget>
          </t-grid-cell>
          <t-grid-cell>
            <t-count-widget :label="$t('fields.email_templates')" icon="envelope" card>
              {{ organization.email_templates_count }}
            </t-count-widget>
          </t-grid-cell>
          <t-grid-cell>
            <t-count-widget :label="$t('fields.registration_codes')" icon="key" card>
              {{ organization.registration_codes_count }}
            </t-count-widget>
          </t-grid-cell>
        </t-grid-row>
        <t-grid-row>
          <t-grid-cell>
            <t-count-widget :label="$t('fields.ar_applicants')" icon="graduation-cap" card>
              {{ tenant.available_applicants_count }}
            </t-count-widget>
          </t-grid-cell>
          <t-grid-cell>
            <t-count-widget :label="$t('fields.ar_companies')" icon="building" card>
              {{ tenant.organizations_count }}
            </t-count-widget>
          </t-grid-cell>
        </t-grid-row>
      </t-grid>
    </t-page>
  </t-body>
</template>

<script>
import Vue from 'vue';
import Organization from '~/models/Organization';
import Tenant from '~/models/Tenant';

export default Vue.extend({

  data: () => ({
    organization: null,
    tenant: null,
  }),

  async fetch() {
    this.organization = await Organization.$find(this.$auth.me.organization_id);
    this.tenant = await Tenant.$find(this.organization.tenant_id);
  },

  computed: {
    me() {
      return this.$auth.me;
    },

    user() {
      return this.me.user;
    },

    hasProfiles() {
      return this.organization?.profiles_count !== 0;
    },

    hasEmailTemplates() {
      return this.organization?.email_templates_count !== 0;
    },

    hasAlerts() {
      return this.organization?.alerts_count !== 0;
    },

    hasRegistrationCodes() {
      return this.organization?.registration_codes_count !== 0;
    },
  },
});
</script>
<style lang="scss" scoped>
.intro {
  margin-top: $s-8;
  margin-bottom: $s-16;

  .organization-logo {
    margin-bottom: $s-4;
  }

  &__welcome {
    font-weight: 300;
    font-size: $font-size-2xl;
  }

  &__name {
    font-size: $font-size-3xl;
  }
}

.section {
  &__title {
  }
}

.onboarding-task {
  border-radius: 8px;
  border: 1px solid var(--outline-color);
  padding: 1rem;
  margin: 1rem 0;
  position: relative;
  overflow: hidden;
  min-height: 6rem;

  &--completed {
    background-color: var(--background-color);
    color: grey;
    text-decoration: line-through;
  }

  &__order {
    position: absolute;
    left: 1rem;
    bottom: 0;
    font-size: 5rem;
    font-weight: bolder;
    color: var(--skeleton-color);
    line-height: 1;
  }

  &__body {
    position: relative;
    z-index: 10;
    display: flex;
    justify-content: flex-end;
  }

  &__title {
    font-size: 1.25rem;
    text-align: right;
  }
}
</style>
